//import config from 'config';
//import config from '../webpack.config';
import { authHeader, service, serviceBuild } from "../_helpers";

export const documentService = {
  getList,
  getByMatterID,
  getByDocumentID,
  getDocumentDetailsByDocID,
  getByBondOfSecurityID,
  getByCommentID,
  getByClaimID,
  getByMeetingID,
  getByAssetID,
  add,

  filePondUpload,
  uploadDocument,
  getDocumentWithoutDataByID,

  addMatterDocument,
  addMatterComment,
  addByAuditorID,
  addByBeneficiaryID,
  addByDirectorID,
  addByExecutorID,
  addByPersonOfInterestID,
  addByMeetingID,
  _delete,
  getFile,
  checkAssetFile,
  sendDocumentWithOutlook,
  GetByMatterIDFXList,
  getMissingDocuments,
  checkDocuments,
};

var documents = [];
for (var i = 1; i < 5; i++) {
  documents.push({
    id: i,
    dateUploaded: "dateUploaded" + i,
    documentName: "documentName" + i,
    view: "View Document",
  });
}

function getList(skip, take) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return new Promise((resolve, reject) => {
    resolve({
      items: documents.slice(skip, take + skip),
      itemsCount: documents.length,
    });
  });

  //return fetch(`/matter/getall`, requestOptions)
  //.then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);
}

function getMissingDocuments(id, matterID) {
  return service.fetch.get(
    serviceBuild.buildUrl(`/api/document/getMissingDocuments`, {
      id,
      matterID,
    }),
  );
}
function checkDocuments(id, matterID) {
  return service.fetch.get(
    serviceBuild.buildUrl(`/api/document/checkDocuments`, { id, matterID }),
  );
}
function GetByMatterIDFXList(matterID, fxProviderId, beneficiaryId) {
  return service.fetch.get(
    serviceBuild.buildUrl(`/api/document/getByMatterIDFXList`, {
      matterID,
      fxProviderId,
      beneficiaryId,
    }),
  );
}
function getByMatterID(id, skip, take, searchString, selectedDoctype) {
  //const requestOptions = {
  //  method: 'GET',
  //  headers: authHeader()
  //};

  //var result = fetch(serviceBuild.buildUrl(`/api/document/getbymatterid`, { id, skip, take, searchString, selectedDoctype }), requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);
  //return result;
  return service.fetch.get(
    serviceBuild.buildUrl(`/api/document/getbymatterid`, {
      id,
      skip,
      take,
      searchString,
      selectedDoctype,
    }),
  );
}

function getByMeetingID(id) {
  //const requestOptions = {
  //  method: 'GET',
  //  headers: authHeader()
  //};

  //var result = fetch(serviceBuild.buildUrl(`/api/document/getbymeetingid`, { id }), requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);
  //return result;
  return service.fetch.get(
    serviceBuild.buildUrl(`/api/document/getbymeetingid`, { id }),
  );
}

function getByBondOfSecurityID(id) {
  //const requestOptions = {
  //  method: 'GET',
  //  headers: authHeader()
  //};

  //var result = fetch(serviceBuild.buildUrl(`/api/document/getbybondofsecurityid`, {id}), requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);
  //return result;
  return service.fetch.get(
    serviceBuild.buildUrl(`/api/document/getbybondofsecurityid`, { id }),
  );
}

function sendDocumentWithOutlook(documentID) {
  return service.fetch.get(
    serviceBuild.buildUrl(`/api/document/senddocumentwithoutlook`, {
      documentID,
    }),
  );
}

function getByCommentID(id) {
  //const requestOptions = {
  //  method: 'GET',
  //  headers: authHeader()
  //};

  //var result = fetch(serviceBuild.buildUrl(`/api/document/getbybondofsecurityid`, {id}), requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);
  //return result;
  return service.fetch.get(
    serviceBuild.buildUrl(`/api/document/getbycommentid`, { id }),
  );
}

function getByClaimID(id) {
  //const requestOptions = {
  //  method: 'GET',
  //  headers: authHeader()
  //};

  //var result = fetch(serviceBuild.buildUrl(`/api/document/getbyclaimid`, { id }), requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);
  //return result;
  return service.fetch.get(
    serviceBuild.buildUrl(`/api/document/getbyclaimid`, { id }),
  );
}

function getByAssetID(id) {
  //const requestOptions = {
  //  method: 'GET',
  //  headers: authHeader()
  //};

  //var result = fetch(serviceBuild.buildUrl(`/api/document/getbyassetid`, { id }), requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);
  //return result;
  return service.fetch.get(
    serviceBuild.buildUrl(`/api/document/getbyassetid`, { id }),
  );
}

function getByDocumentID(id) {
  //const requestOptions = {
  //  method: 'POST',
  //  headers: authHeader(),
  //  body: JSON.stringify(id)
  //};

  //var result = fetch(`/api/document/downloaddocument`, requestOptions)/*.then(handleResponse)*/;
  //return result;
  return service.fetch.post(
    `/api/document/downloaddocument`,
    JSON.stringify(id),
  );
}

function getDocumentDetailsByDocID(id) {
  //const requestOptions = {
  //  method: 'GET',
  //  headers: authHeader()
  //};

  //var result = fetch(serviceBuild.buildUrl(`/api/document/getbydocumentid`, { id }), requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);
  //return result;
  return service.fetch.get(
    serviceBuild.buildUrl(`/api/document/getbydocumentid`, { id }),
  );
}

function addByPersonOfInterestID(id, personOfInterestID) {
  return service.fetch.post("/api/documentPersonOfInterest/add", {
    DocumentID: id,
    PersonOfInterestID: personOfInterestID,
  });
}

function addByBeneficiaryID(id, beneficiaryID) {
  return service.fetch.post("/api/documentBeneficiary/add", {
    DocumentID: id,
    BeneficiaryID: beneficiaryID,
  });
}

function addByAuditorID(id, auditorID) {
  return service.fetch.post("/api/documentAuditor/add", {
    DocumentID: id,
    AuditorID: auditorID,
  });
}

function addByDirectorID(id, directorID) {
  return service.fetch.post("/api/documentDirector/add", {
    DocumentID: id,
    DirectorID: directorID,
  });
}

function addByExecutorID(id, executorID) {
  return service.fetch.post("/api/documentExecutor/add", {
    DocumentID: id,
    ExecutorID: executorID,
  });
}

function addByMeetingID(id, meetingID) {
  return service.fetch.post("/api/documentMeeting/add", {
    DocumentID: id,
    MeetingID: meetingID,
  });
}

function filePondUpload(file) {
  return service.fetch.post(`/api/document/FilePondUpload`, file);
}

function uploadDocument(endpoint, file) {
  debugger;
  return service.fetch.post(`/api/document/${endpoint}`, file);
}

function getDocumentWithoutDataByID(id) {
  return service.fetch.get(
    serviceBuild.buildUrl(`/api/document/GetDocumentWithoutDataByID`, { id }),
  );
}

function getDocumentByID(id) {}

function addMatterDocument(file) {
  return service.fetch.post(`/api/document/AddMatterDocument`, file);
}

function addMatterComment(formData) {
  return service.fetch.post("/api/document/AddMatterComment", formData);
}

function add(file) {
  //const requestOptions = {
  //  method: 'POST',
  //  headers: authHeader(),
  //  body: JSON.stringify(file)
  //};
  //var result = fetch(`/api/document/UploadFile`, requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);
  //return result;

  return service.fetch.post(`/api/document/UploadFile`, file);

  //let data = new FormData();
  //let filetoUpload = file.target.files[0];
  ////let docModel = { ID: 1 };
  ////data.append('id', docModel);
  //data.append('formFile', filetoUpload);

  //let docModel = { ID: 1, FormFile: data };
  ////docModel = JSON.stringify(docModel);

  //const requestOptions = {
  //  method: 'POST',
  //  headers: { 'Content-Type': 'application/json' },
  //  //headers: { 'Content-Type': 'multipart/form-data' },
  //  body: { 'DocumentViewModel': docModel }//, 'file': filetoUpload }
  //  //body: docModel // , 'file': file }
  //  //body: JSON.stringify(documentDetails)//{ 'documentViewModel': documentDetails }//, 'file': file }
  //  //body: document
  //};
  //
  //var result = fetch(`/api/document/add`, requestOptions).then(handleResponse);
  //return result;
}

function _delete(id) {
  //const requestOptions = {
  //  method: 'DELETE',
  //  headers: authHeader(),
  //  body: JSON.stringify(id)
  //};

  //var result = fetch(`/api/document/_delete`, requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);
  //return result;
  return service.fetch.delete(`/api/document/_delete`, JSON.stringify(id));
}

function getFile(id) {
  //const requestOptions = {
  //  method: 'POST',
  //  headers: authHeader(),
  //  body: JSON.stringify(id)
  //};

  ////var result = fetch(`/api/document/checkMatterFile`, requestOptions).then(serviceResponse.handleResponse, serviceResponse.handleError);
  //var result = fetch(`/api/document/CheckAndGetFile`, requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);
  ////var result = fetch(`/api/document/CheckAndGetFile`, requestOptions).then(serviceResponse.handleResponse, serviceResponse.handleError);
  //return result;
  return service.fetch.post(
    `/api/document/CheckAndGetFile`,
    JSON.stringify(id),
  );
}

function checkAssetFile(assetID, documentTypeID) {
  //const requestOptions = {
  //  method: 'POST',
  //  headers: authHeader(),
  //  body: JSON.stringify(id)
  //};

  ////var result = fetch(`/api/document/checkMatterFile`, requestOptions).then(serviceResponse.handleResponse, serviceResponse.handleError);
  //var result = fetch(`/api/document/CheckAndGetFile`, requestOptions)
  //  .then(serviceResponse.handleResponse)
  //  .catch(serviceResponse.handleError);
  ////var result = fetch(`/api/document/CheckAndGetFile`, requestOptions).then(serviceResponse.handleResponse, serviceResponse.handleError);
  //return result;

  return service.fetch.get(
    serviceBuild.buildUrl(`/api/document/checkassetfile`, {
      assetID,
      documentTypeID,
    }),
  );
}
