import React, { Component } from "react";
import {
  DropdownItem,
  DropdownMenu,
  Col,
  DropdownToggle,
  UncontrolledDropdown,
  Nav,
  Label,
  NavItem,
  NavLink,
  Button,
  Row,
} from "reactstrap";
import { Dropdown } from "semantic-ui-react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { RoleEnum } from "../../_enums";
import {
  AppHeaderDropdown,
  AppNavbarBrand,
  AppSidebarToggler,
} from "@coreui/react";
import logoDeceased from "../../assets/img/logo/1x/DeceasedEstate.png";
import logoCuratorship from "../../assets/img/logo/3x/Curatorship.png";
import logoFlowConsult from "../../assets/img/logo/3x/FlowConsult.png";
import logoInsolvencies from "../../assets/img/logo/3x/Insolvencies.png";
import logoTrust from "../../assets/img/logo/3x/Trust.png";
import { history } from "../../_helpers";
import { connect } from "react-redux";
import { AgGridReact } from "ag-grid-react";
import { DownloadURLFile } from "../../_components/UrlDownload";
import { flowService } from "../../_services/flow.service";
import {
  companyDetailsService,
  matterWatchListService,
  userService,
} from "../../_services";
import ModalBoxRenderer from "../../views/Components/ModalBoxRenderer";
import { SystemTypeEnum } from "../../views/Enums/SystemTypeEnum";
import SystemViewRenderer from "../../views/Components/ConditionalRenderers/SystemViewRenderer";
import jwt from "jsonwebtoken";
import { templateTypeEnum } from "../../views/Enums/TemplateTypeEnum";
import "../../_components/systemChanges.css";
import _ from "lodash";
import { flowChatActions } from "../../_actions/flowChat.actions";
import { NotificationManager } from "react-notifications";
import { flowChatService, intermediaryService } from "../../_services";
import * as signalR from "@microsoft/signalr";
import {
  intermediaryActions,
  matterActions,
  matterwatchlistActions,
  userActions,
} from "../../_actions";
import DeveloperRenderer from "../../views/Components/ConditionalRenderers/DeveloperRederer";
import { matterWatchListConstants } from "../../_constants";
import { sys } from "typescript";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {};

class DefaultHeader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      changeGrid: this.getInitialSystemChanges(),
      changeWhatsappGrid: this.getWhatsappMessages(),
      companySettingsVisible: true,
      contactModalOpen: false,
      changesModalOpen: false,
      newMessageModalOpen: false,
      systemType: props.systemType,
      showMultiSystemDropDown: this.getInitialSystemShowState(),
      newBadgeVisible: false,
      newMessagesVisible: false,
      newMessagesNumber: 0,
      viewWatchList: false,
      isChatFlow: false,
    };

    if (this.props.roles.includes(RoleEnum.UserMatterAccess.Name)) {
      this.state.companySettingsVisible = false;
    }
    if (this.props.roles.includes(RoleEnum.JointAppointee.Name)) {
      this.state.companySettingsVisible = false;
    }

    this.handleLogout = this.handleLogout.bind(this);
    this.backOnClick = this.backOnClick.bind(this);

    this.initializeNewChangeFlag();

    this.getIntermediaryIsChatFlow();

    this.initialNewWhatsappDataSource();
  }

  componentDidMount = async () => {};

  async componentDidUpdate(prevProps, prevState) {
    if (
      this.props.intermediary.isFlowChat !==
        prevProps.intermediary.isFlowChat ||
      this.props.intermediaryID !== prevProps.intermediaryID
    ) {
      if (this.props.intermediary.isFlowChat && this.props.intermediaryID) {
        this.getIntermediaryIsChatFlow();
      }
    }
  }

  initialSignalRConnection = async () => {
    let urlPath = window.location.origin;

    //if (!urlPath.includes("localhost:")) {
    const connection = new signalR.HubConnectionBuilder()
      .withUrl(urlPath + "/api/chatHub", {})
      .configureLogging(signalR.LogLevel.Information)
      .build();

    async function start() {
      //https://qa2.fennecflow.com/api/flowchat/whatsappIncomingMessage
      try {
        await connection.start();
        //NotificationManager.info("SignalR Connected.");
      } catch (err) {
        NotificationManager.info(err.message);
      }
    }

    const initialNewWhatsappDataSource = async () => {
      let data = await flowChatService.getUnreadMessage();
      let stateObj = this.state.changeWhatsappGrid;
      stateObj.rowData = data;
      if (data.length > 0) {
        this.setState({
          changeWhatsappGrid: stateObj,
          newMessagesVisible: true,
          newMessagesNumber: data.length,
        });
      } else {
        this.setState({
          newMessagesVisible: false,
        });
      }
    };

    async function on() {
      try {
        await connection.on(
          "ReceiveMessage",
          async (user, message, matterID) => {
            if (
              flowChatService.doesThisNotificationBelongToCurrentuser(matterID)
            ) {
              await NotificationManager.success(message, user);
              initialNewWhatsappDataSource();
              //toast.success(message);
            }
          },
        );
      } catch (err) {
        NotificationManager.info(err);
      }
    }

    connection.onclose(async () => {
      await start();
      await on();
    });

    start();
    on();
    //}
  };

  initialNewWhatsappDataSource = async () => {
    let data = await flowChatService.getUnreadMessage();
    let stateObj = this.state.changeWhatsappGrid;
    stateObj.rowData = data;
    if (data.length > 0) {
      this.setState({
        changeWhatsappGrid: stateObj,
        newMessagesVisible: true,
        newMessagesNumber: data.length,
        isChatFlow: true,
      });
    } else {
      this.setState({
        newMessagesVisible: false,
      });
    }
    this.state.isChatFlow;
  };

  getIntermediaryIsChatFlow = async () => {
    //if (this.props.roles.indexOf(RoleEnum.CaptureMatter.Name) > -1) {
    /*this.setState({
        isChatFlow: true,
      });*/
    if (this.props.intermediary.isFlowChat) {
      await this.initialNewWhatsappDataSource();
      this.initialSignalRConnection();
    }
    //}
  };

  getInitialSystemShowState = () => {
    var user = JSON.parse(localStorage.getItem("user"));
    if (!user) return false;

    var decodedUserToken = jwt.decode(user.token, { complete: true });

    var UserSystemTypesString = decodedUserToken.payload["app:UserSystemTypes"];
    var systemTypesString = decodedUserToken.payload["app:SystemTypes"];
    var systemtypeIDArray = _.intersection(
      UserSystemTypesString.split("@"),
      systemTypesString.split("@"),
    );

    if (systemtypeIDArray.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  handleLogout(_e) {
    const { dispatch } = this.props;
    dispatch(userActions.logout());
  }

  backOnClick() {
    //alert(history.location.pathname);
    history.goBack();
    //history.push(`${this.props.history.location.pathname.substr(0, this.props.history.location.pathname.lastIndexOf("/"))}`, {});
    //history.push(`/`, {});  {/*full={{ src: logo, width: 89, height: 25, alt: 'CoreUI Logo' }}*/ }
  }

  initializeNewChangeFlag = async () => {
    var userNewChangesFlag = await userService.getNewChangesFlag();
    if (userNewChangesFlag) {
      this.setState({
        newBadgeVisible: false,
      });
    } else {
      this.setState({
        newBadgeVisible: true,
      });
    }
  };

  getInitialSystemChanges = () => {
    let gridConfigObj = {};

    gridConfigObj.columnDefs = [
      { headerName: "Date", field: "createdDate" },
      {
        headerName: "Change",
        field: "comment",
        tooltipField: "comment",
        flex: 5,
      },
    ];

    gridConfigObj.defaultColDef = {
      sortable: true,
      sortingOrder: ["desc", "asc"],
      resizable: true,
      flex: 1,
    };

    gridConfigObj.rowData = [];
    return gridConfigObj;
  };

  getWhatsappMessages = () => {
    let gridConfigObj = {};

    gridConfigObj.columnDefs = [
      { headerName: "Date & Time", field: "createdDate", flex: 6 },
      {
        headerName: "Deceased Name",
        field: "description",
        tooltipField: "description",
        flex: 5,
        cellStyle: {
          cursor: "pointer",
          "text-decoration": "underline",
          color: "blue",
        },
        onCellClicked: function (params) {
          var model = params.data;
          history.push(`/matters/${model.matterId}/flowChat`, {});
          window.location.reload();
          this.onCloseWhatsappModal();
        }.bind(this),
      },
      {
        headerName: "Contact Name",
        field: "name",
        tooltipField: "name",
        flex: 5,
      },
      {
        headerName: "Message",
        field: "message",
        tooltipField: "name",
        flex: 5,
      },
      {
        headerName: "Tick to clear",
        width: 140,
        checkboxSelection: true,
        flex: 5,
      },
    ];
    gridConfigObj.defaultColDef = {
      sortable: true,
      sortingOrder: ["desc", "asc"],
      rowModelType: "infinite",
      resizable: true,
      flex: 1,
    };

    gridConfigObj.rowData = [];
    return gridConfigObj;
  };
  ClickUnRead_OnClick = async (messageDetails) => {
    //const { dispatch } = this.props;
    await flowChatService.updateReadMessageStatus(messageDetails.id, false);
    /* await dispatch(
      flowChatActions.updateReadMessageStatus(messageDetails.id, false),
    ); */
  };

  ClickRead_OnClick = async (matterDetails) => {
    const { dispatch } = this.props;
    await dispatch(
      flowChatActions.updateReadMessageStatus(matterDetails.id, true),
    );
  };
  //=========================Contact details Modal============================================

  onCloseContactModal = () => {
    this.setState({
      contactModalOpen: false,
    });
  };

  onOpenContactModal = () => {
    this.setState({
      contactModalOpen: true,
    });
  };

  handleSystemSelectionInputChange = async (event, data) => {
    const { value, name } = data;
    await this.setState({ [name]: value });
    await this.submitSystemTypeChange(value);
    window.location.reload();
  };

  submitSystemTypeChange = async (value) => {
    const { dispatch } = this.props;
    var systemType = parseInt(value);
    if (systemType !== 0) {
      var user = JSON.parse(localStorage.getItem("user"));
      await dispatch(userActions.updateSystemType(systemType, user.id));
    } else {
      NotificationManager.error("Please Select Profile");
    }
  };

  onClickSaveChangesFromContactModal = () => {
    this.onCloseContactModal();
  };

  onClickCancelChangesFromContactModal = () => {
    this.onCloseContactModal();
  };

  showContactModalView = () => {
    return (
      <div>
        <Row className="mb-2 pb-0" row>
          <Col md="4" className="text-left">
            <Label
              size="sm"
              style={{ fontWeight: "bold", fontSize: "13px" }}
              className="mb-0 pb-0"
            >
              Sales Enquiries
            </Label>
          </Col>
          <Col md="8"></Col>
        </Row>

        {/* 1st Column */}
        <Row>
          <Col>
            {/*sonja*/}
            <Row className="mb-2 pb-0" row>
              <Col md="4" className="text-right">
                <Label
                  size="sm"
                  style={{ fontWeight: "bold" }}
                  className="mb-0 pb-0"
                >
                  Sonja Wessels
                </Label>
              </Col>
            </Row>
            <Row className="mb-2 pb-0" row>
              <Col md="5" className="text-right">
                <Label size="sm" className="mb-0 pb-0">
                  Contact Number:
                </Label>
              </Col>
              <Col md="5">
                <Label size="sm" className="mb-0 pb-0">
                  +27 82 812 1589
                </Label>
              </Col>
            </Row>
            <Row className="mb-2 pb-0" row>
              <Col md="5" className="text-right">
                <Label size="sm" className="mb-0 pb-0">
                  Email Address:
                </Label>
              </Col>
              <Col md="5">
                <a
                  style={{ fontSize: "0.76563rem" }}
                  href="mailto:sonja@fennecgroup.co.za"
                  className="mb-0 pb-0"
                >
                  sonja@fennecgroup.co.za
                </a>
              </Col>
            </Row>

            {/*rachelle*/}
            <Row className="mb-2 pb-0" row>
              <Col md="4" className="text-right">
                <Label
                  size="sm"
                  style={{ fontWeight: "bold" }}
                  className="mb-0 pb-0"
                >
                  Rachelle Strydom
                </Label>
              </Col>
            </Row>
            <Row className="mb-2 pb-0" row>
              <Col md="5" className="text-right">
                <Label size="sm" className="mb-0 pb-0">
                  Contact Number:
                </Label>
              </Col>
              <Col md="5">
                <Label size="sm" className="mb-0 pb-0">
                  +27 76 479 2068
                </Label>
              </Col>
            </Row>
            <Row className="mb-2 pb-0" row>
              <Col md="5" className="text-right">
                <Label size="sm" className="mb-0 pb-0">
                  Email Address:
                </Label>
              </Col>
              <Col md="5">
                <a
                  style={{ fontSize: "0.76563rem" }}
                  href="mailto:rachelle@fennecgroup.co.za"
                  className="mb-0 pb-0"
                >
                  rachelle@fennecgroup.co.za
                </a>
              </Col>
            </Row>
          </Col>

          {/*  2nd Column */}

          <Col>
            {/*lorain*/}
            <Row className="mb-2 pb-0" row>
              <Col md="5" className="text-right">
                <Label
                  size="sm"
                  style={{ fontWeight: "bold" }}
                  className="mb-0 pb-0"
                >
                  Lorain Venter
                </Label>
              </Col>
            </Row>
            <Row className="mb-2 pb-0" row>
              <Col md="6" className="text-right">
                <Label size="sm" className="mb-0 pb-0">
                  Contact Number:
                </Label>
              </Col>
              <Col md="3">
                <Label size="sm" className="mb-0 pb-0">
                  +27 83 397 6536
                </Label>
              </Col>
            </Row>
            <Row className="mb-2 pb-0" row>
              <Col md="6" className="text-right">
                <Label size="sm" className="mb-0 pb-0">
                  Email Address:
                </Label>
              </Col>
              <Col md="2">
                <a
                  style={{ fontSize: "0.76563rem" }}
                  href="mailto:lorain@fennecgroup.co.za"
                  className="mb-0 pb-0"
                >
                  lorain@fennecgroup.co.za
                </a>
              </Col>
            </Row>

            {/*elizabeth*/}
            <Row className="mb-2 pb-0" row>
              <Col md="5" className="text-right">
                <Label
                  size="sm"
                  style={{ fontWeight: "bold" }}
                  className="mb-0 pb-0"
                >
                  Elizabeth Spanellis
                </Label>
              </Col>
            </Row>
            <Row className="mb-2 pb-0" row>
              <Col md="6" className="text-right">
                <Label size="sm" className="mb-0 pb-0">
                  Contact Number:
                </Label>
              </Col>
              <Col md="3">
                <Label size="sm" className="mb-0 pb-0">
                  +27 74 134 6226
                </Label>
              </Col>
            </Row>
            <Row className="mb-2 pb-0" row>
              <Col md="6" className="text-right">
                <Label size="sm" className="mb-0 pb-0">
                  Email Address:
                </Label>
              </Col>
              <Col md="2">
                <a
                  style={{ fontSize: "0.76563rem" }}
                  href="mailto:lorain@fennecgroup.co.za"
                  className="mb-0 pb-0"
                >
                  elizabeth@fennecgroup.co.za
                </a>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="mb-2 pb-0" row>
          <Col md="4" className="text-left">
            <Label
              size="sm"
              style={{ fontWeight: "bold", fontSize: "13px" }}
              className="mb-0 pb-0"
            >
              General Enquiries
            </Label>
          </Col>
          <Col md="8"></Col>
        </Row>

        <Row>
          <Col>
            <Row className="mb-2 pb-0" row>
              <Col md="2" className="text-right">
                <Label size="sm" className="mb-0 pb-0">
                  Contact Number:
                </Label>
              </Col>
              <Col md="3">
                <Label size="sm" className="mb-0 pb-0">
                  +27 82 885 4425
                </Label>
              </Col>
            </Row>

            <Row className="mb-2 pb-0" row>
              <Col md="2" className="text-right">
                <Label size="sm" className="mb-0 pb-0">
                  Email Address:
                </Label>
              </Col>
              <Col md="3">
                <a
                  style={{ fontSize: "0.76563rem" }}
                  className="mb-0 pb-0"
                  href="mailto:info@fennecgroup.co.za"
                >
                  Info@fennecgroup.co.za
                </a>
              </Col>
            </Row>

            {/* <Row className="mb-2 pb-0" row>
          <Col md="2" className="text-right">
            <Label size="sm" className="mb-0 pb-0">Address:</Label>
          </Col>
         <Col md="8">
            <Label size="sm" className="mb-0 pb-0">1st Floor, Building 31, Woodlands Office Park, 16 Woodlands Drive, Woodmead, Sandton, South Africa, 2191</Label>
          </Col>
        </Row>*/}
          </Col>
        </Row>
      </div>
    );
  };

  setSystemTypeListDropdown = () => {
    var user = JSON.parse(localStorage.getItem("user"));
    if (!user) return [];

    var decodedUserToken = jwt.decode(user.token, { complete: true });
    var systemTypesString = decodedUserToken.payload["app:SystemTypes"];
    var SystemTypes = [];

    var UserSystemTypesString = decodedUserToken.payload["app:UserSystemTypes"];
    var systemTypesString = decodedUserToken.payload["app:SystemTypes"];
    var systemtypeIDArray = _.intersection(
      UserSystemTypesString.split("@"),
      systemTypesString.split("@"),
    );

    if (systemtypeIDArray.length > 0) {
      for (var i = 0; i < systemtypeIDArray.length; i++) {
        switch (parseInt(systemtypeIDArray[i])) {
          case SystemTypeEnum.Liquidation.id:
            SystemTypes.push({
              key: SystemTypeEnum.Liquidation.id,
              text: "Insolvencies",
              value: SystemTypeEnum.Liquidation.id,
            });
            break;
          case SystemTypeEnum.EstateLate.id:
            SystemTypes.push({
              key: SystemTypeEnum.EstateLate.id,
              text: "Deceased Estate",
              value: SystemTypeEnum.EstateLate.id,
            });
            break;
          case SystemTypeEnum.Curatorship.id:
            SystemTypes.push({
              key: SystemTypeEnum.Curatorship.id,
              text: "Curatorship",
              value: SystemTypeEnum.Curatorship.id,
            });
            break;
          case SystemTypeEnum.FlowConsult.id:
            SystemTypes.push({
              key: SystemTypeEnum.FlowConsult.id,
              text: "Flow Consult",
              value: SystemTypeEnum.FlowConsult.id,
            });
            break;
          case SystemTypeEnum.Trust.id:
            SystemTypes.push({
              key: SystemTypeEnum.Trust.id,
              text: "Trust",
              value: SystemTypeEnum.Trust.id,
            });
            break;
        }
      }
    }
    return SystemTypes;
  };

  downloadDocument_OnClick = () => {
    DownloadURLFile(
      "/api/template/viewRawTemplate?templateTypeID=" +
        templateTypeEnum.TrainingManual.ID,
      false,
    );
  };

  onOpenChangesModal = () => {
    this.setState({
      changesModalOpen: true,
      newBadgeVisible: false,
    });
  };
  onOpenMessageModal = () => {
    this.setState({
      newMessageModalOpen: true,
      newMessagesVisible: true,
    });
  };

  onCloseWhatsappModal = () => {
    this.setState({
      newMessageModalOpen: false,
    });
  };
  onCloseChangesModal = () => {
    this.setState({
      changesModalOpen: false,
    });
  };

  /*/ onWhatsappMessageClicked =(params)=>{
    var model = params.data;
    history.push(`/matters/${model.matterId}/flowChat`, {});
    this.onCloseWhatsappModal();
  }*/
  removeSelectedRows = async (params) => {
    this.gridApi = params.api;
    var selectedRows = this.gridApi.getSelectedRows();
    var model = selectedRows[0];
    await flowChatService.updateSelectedWhatsappMessage(model.id);
    await this.initialNewWhatsappDataSource();
  };
  initialChangesDataSource = async () => {
    let data = await flowService.getChangeslist();
    let stateObj = this.state.changeGrid;
    stateObj.rowData = data;
    this.setState({ changeGrid: stateObj });

    /*    var dataSource = {
      rowCount: null,
      getRows: async function (params) {
        var response = await flowService.getChangeslist();
        var rowsThisPage = response;
        var lastRow = response.length;
        params.successCallback(rowsThisPage, lastRow);
      }
    };


    this.gridApi.setDatasource(dataSource);*/
  };

  onChangeGridReady = (params) => {
    if (!params) return;
    this.api = params;
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    //this.initialChangesDataSource();
    //params.api.refreshCells(params);
    //params.api.sizeColumnsToFit();
  };

  showChangesModalView = () => {
    return (
      <div>
        <Col sm="12">
          <div className="ag-theme-balham">
            <AgGridReact
              id={"changeGrid"}
              columnDefs={this.state.changeGrid.columnDefs}
              defaultColDef={this.state.changeGrid.defaultColDef}
              rowData={this.state.changeGrid.rowData}
              onGridReady={this.initialChangesDataSource}
              pagination={true}
              rowSelection="single"
              paginationPageSize={20}
              context={this.state.changeGrid.context}
              domLayout="autoHeight"
            />
          </div>
        </Col>
      </div>
    );
  };
  onFilterTextBoxChanged = () => {
    let filterText = document.getElementById("filter-text-box").value;

    //this.matterAGGridApi
    const updateData = (filterText) => {
      const dataSource = {
        rowCount: undefined,
        getRows: async function (params) {
          //let data = await flowChatService.getSearchUnreadMessage(params.startRow, params.endRow, params.sortModel, filterText)

          params.successCallback(data.items, data.itemsCount);
        },
      };

      this.matterAGGridApi.setDatasource(dataSource);
    };

    updateData(filterText);
  };
  showNewWhatsappModalView = () => {
    return (
      <div>
        <Col sm="12">
          <div className="ag-theme-balham">
            {/*<Input*/}
            {/*  className="mb-2"*/}
            {/*  type="text"*/}
            {/*  id="filter-text-box"*/}
            {/*  placeholder="Filter..."*/}
            {/*  onInput={ _.debounce(this.onFilterTextBoxChanged, 500)}*/}
            {/*  />*/}
            <AgGridReact
              id={"changeWhatsappGrid"}
              columnDefs={this.state.changeWhatsappGrid.columnDefs}
              defaultColDef={this.state.changeWhatsappGrid.defaultColDef}
              rowData={this.state.changeWhatsappGrid.rowData}
              onGridReady={() => {}}
              pagination={true}
              rowSelection="multiple"
              paginationPageSize={20}
              context={this.state.changeWhatsappGrid.context}
              domLayout="autoHeight"
              suppressRowClickSelection={true}
              onSelectionChanged={this.removeSelectedRows}
            />
          </div>
        </Col>
      </div>
    );
  };

  render() {
    /*
    //eslint-disable-next-line full={{ src: logo, width: 110, height: 40, alt: 'CoreUI Logo' }}
    */

    const { children, match, ...attributes } = this.props;
    return (
      <React.Fragment>
        <ModalBoxRenderer
          className="systemChangesModal"
          isOpen={this.state.contactModalOpen}
          showSavedButton={false}
          onClose={this.onCloseContactModal}
          modalSaveButtonText="Close"
          modalTitle="Contact Information"
          onClickSaveChanges={this.onClickSaveChangesFromContactModal.bind(
            this,
          )}
          onClickCancelChanges={this.onClickCancelChangesFromContactModal.bind(
            this,
          )}
        >
          {this.showContactModalView()}
        </ModalBoxRenderer>

        <ModalBoxRenderer
          className="systemChangesModal"
          isOpen={this.state.changesModalOpen}
          modalCancelButtonText="Close"
          showSavedButton={false}
          onClose={this.onCloseChangesModal}
          modalSaveButtonText="Close"
          modalTitle="New System Changes"
          onClickCancelChanges={this.onCloseChangesModal}
        >
          {this.showChangesModalView()}
        </ModalBoxRenderer>

        <ModalBoxRenderer
          className="systemChangesModal"
          isOpen={this.state.newMessageModalOpen}
          modalCancelButtonText="Close"
          showSavedButton={false}
          onClose={this.onCloseWhatsappModal}
          modalSaveButtonText="Close"
          modalTitle="Flow Chat Notifications"
          onClickCancelChanges={this.onCloseWhatsappModal}
        >
          {this.showNewWhatsappModalView()}
        </ModalBoxRenderer>

        <AppSidebarToggler className="d-lg-none" display="md" mobile />

        <SystemViewRenderer system={SystemTypeEnum.EstateLate.id}>
          <AppNavbarBrand
            full={{ src: logoDeceased, width: "80%", alt: "Fennec" }}
            // minimized={{ src: sygnet, width: 30, height: 30, alt: 'CoreUI Logo' }}
          />
        </SystemViewRenderer>
        <SystemViewRenderer system={SystemTypeEnum.Curatorship.id}>
          <AppNavbarBrand
            full={{ src: logoCuratorship, width: "80%", alt: "Fennec" }}
            // minimized={{ src: sygnet, width: 30, height: 30, alt: 'CoreUI Logo' }}
          />
        </SystemViewRenderer>
        <SystemViewRenderer system={SystemTypeEnum.FlowConsult.id}>
          <AppNavbarBrand
            full={{ src: logoFlowConsult, width: "80%", alt: "Fennec" }}
            // minimized={{ src: sygnet, width: 30, height: 30, alt: 'CoreUI Logo' }}
          />
        </SystemViewRenderer>
        <SystemViewRenderer system={SystemTypeEnum.Liquidation.id}>
          <AppNavbarBrand
            full={{ src: logoInsolvencies, width: "80%", alt: "Fennec" }}
            // minimized={{ src: sygnet, width: 30, height: 30, alt: 'CoreUI Logo' }}
          />
        </SystemViewRenderer>
        <SystemViewRenderer system={SystemTypeEnum.Trust.id}>
          <AppNavbarBrand
            full={{ src: logoTrust, width: "80%", alt: "Fennec" }}
            // minimized={{ src: sygnet, width: 30, height: 30, alt: 'CoreUI Logo' }}
          />
        </SystemViewRenderer>

        <AppSidebarToggler className="d-md-down-none" display="lg" />

        <div>
          <Button
            type="button"
            onClick={this.backOnClick}
            class="btn btn-outline-dark btn-sm"
          >
            Back
          </Button>
        </div>

        {this.state.showMultiSystemDropDown && (
          //  <Input type="select" name="systemType" bsSize="sm" value={this.state.systemType} style={{ marginLeft: '10px', maxWidth: '12%' }} onChange={this.handleSystemSelectionInputChange}>
          //    <option value="0">Please Select Profile</option>
          //    <option value="1">Insolvencies</option>
          //    <option value="2">Deceased Estates</option>
          //    <option value="3">Curatorship</option>
          //</Input>

          <Dropdown
            placeholder="Please select"
            selection
            value={this.state.systemType}
            options={this.setSystemTypeListDropdown()}
            onChange={this.handleSystemSelectionInputChange}
            style={{ marginLeft: "10px", maxWidth: "12%" }}
            required
          />
        )}
        <Label style={{ marginLeft: "10px", maxWidth: "12%" }}>
          {this.props.intermediary.name}
        </Label>
        {/*<Nav className="d-md-down-none" navbar>
          <NavItem className="px-3">
          <NavLink href="/">Dashboard</NavLink>
          </NavItem>
          <NavItem className="px-3">
          <NavLink href="#/UsersReact">Users</NavLink>
          </NavItem>
          <NavItem className="px-3">
          <NavLink href="#/Login">Login</NavLink>
          </NavItem>
          <NavItem className="px-3">
          <NavLink href="#">Settings</NavLink>
          </NavItem>
          </Nav>*/}
        <Nav className="ml-auto" navbar>
          <NavItem className="px-3">
            <NavLink tag={Link} to="/changepassword">
              <i className="fa fa-key"></i> Change Password
            </NavLink>
          </NavItem>

          {!_.includes(this.props.roles, "User Matter Access") && (
            <NavItem className="px-3">
              <NavLink
                onClick={this.onOpenChangesModal}
                style={{ cursor: "pointer", color: "#73818f" }}
              >
                <i className="fa fa-question-circle"></i> New Changes{" "}
              </NavLink>
            </NavItem>
          )}

          {this.state.newBadgeVisible && (
            <Label
              color="red"
              style={{
                marginLeft: "-12px",
                background: "#21ba45",
                borderRadius: "30px",
                color: "#fff",
                content: "attr(badge)",
                fontSize: "11px",
                marginTop: "-10px",
                minWidth: "20px",
                padding: "3px",
                textAlign: "center",
              }}
            >
              New
            </Label>
          )}
          {this.props.intermediary.isFlowChat && (
            <NavItem className="px-3">
              <NavLink
                onClick={this.onOpenMessageModal}
                style={{ cursor: "pointer", color: "#73818f" }}
              >
                <i className="fa fa-comments"></i> Flow Chat Notifications
              </NavLink>
            </NavItem>
          )}

          {this.state.newMessagesVisible &&
            this.state.newMessagesNumber > 0 &&
            this.props.intermediary.isFlowChat && (
              <Label
                color="red"
                style={{
                  marginLeft: "-12px",
                  background: "#21ba45",
                  borderRadius: "30px",
                  color: "#fff",
                  content: "attr(badge)",
                  fontSize: "11px",
                  marginTop: "-10px",
                  minWidth: "20px",
                  padding: "3px",
                  textAlign: "center",
                }}
              >
                {" "}
                {this.state.newMessagesNumber}
              </Label>
            )}

          <AppHeaderDropdown className="px-3" direction="down">
            <UncontrolledDropdown>
              <DropdownToggle nav>
                {!_.includes(this.props.roles, "User Matter Access") && (
                  <NavLink href="#">
                    <i className="fa fa-question-circle"></i> FAQ
                  </NavLink>
                )}
              </DropdownToggle>
              <DropdownMenu right style={{ right: "auto" }}>
                <DropdownItem onClick={this.downloadDocument_OnClick}>
                  {" "}
                  User Manual
                </DropdownItem>
                <DropdownItem> Questions</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </AppHeaderDropdown>

          <NavItem className="px-3">
            <NavLink
              style={{ cursor: "pointer", color: "#73818f" }}
              onClick={this.onOpenContactModal}
            >
              <i className="fa fa-envelope contact"></i> Contact
            </NavLink>
          </NavItem>

          <AppHeaderDropdown className="px-3" direction="down">
            <UncontrolledDropdown>
              <DropdownToggle nav>
                {!_.includes(this.props.roles, "User Matter Access") && (
                  <NavLink href="#">
                    <i className="fa fa-files-o"></i> Legal
                  </NavLink>
                )}
              </DropdownToggle>
              <DropdownMenu right style={{ right: "auto" }}>
                <DropdownItem tag={Link} to="/legal/termsandconditions">
                  {" "}
                  Terms and Conditions
                </DropdownItem>
                <DropdownItem tag={Link} to="/legal/privacyandsecuritypolicy">
                  {" "}
                  Privacy and Security Policy
                </DropdownItem>
                <DropdownItem tag={Link} to="/legal/informationofficer">
                  {" "}
                  Information Officer
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </AppHeaderDropdown>
          {this.state.companySettingsVisible &&
            _.includes(this.props.roles, "Admin") && (
              <AppHeaderDropdown className="px-3" direction="down">
                <UncontrolledDropdown>
                  <DropdownToggle nav>
                    <NavLink href="#">
                      <i className="fa fa-cog"></i> Settings
                    </NavLink>
                  </DropdownToggle>
                  <DropdownMenu right={false}>
                    <DropdownItem tag={Link} to="/details">
                      Organisation Settings
                    </DropdownItem>

                    <SystemViewRenderer
                      system={[
                        SystemTypeEnum.EstateLate.id,
                        SystemTypeEnum.FlowConsult.id,
                      ]}
                    >
                      <DropdownItem tag={Link} to="/lndoptions">
                        {" "}
                        L&D Options
                      </DropdownItem>
                    </SystemViewRenderer>
                    <DropdownItem tag={Link} to="/auctioneers">
                      {" "}
                      Auctioneers
                    </DropdownItem>

                    <DropdownItem tag={Link} to="/bondofsecurityproviders">
                      {" "}
                      Bond of Security Providers
                    </DropdownItem>
                    <DropdownItem tag={Link} to="/fxAssistProviders">
                      FX Assist Providers
                    </DropdownItem>

                    <DropdownItem tag={Link} to="/globalcreditors">
                      {" "}
                      Global Creditors
                    </DropdownItem>

                    <SystemViewRenderer
                      system={[
                        SystemTypeEnum.Liquidation.id,
                        SystemTypeEnum.EstateLate.id,
                        SystemTypeEnum.FlowConsult.id,
                      ]}
                    >
                      <DropdownItem tag={Link} to="/globalexecutors">
                        {" "}
                        Global Executors
                      </DropdownItem>
                    </SystemViewRenderer>

                    <SystemViewRenderer system={SystemTypeEnum.Trust.id}>
                      <DropdownItem tag={Link} to="/globalfounders">
                        {" "}
                        Global Founders
                      </DropdownItem>

                      <DropdownItem tag={Link} to="/globalbeneficiaries">
                        {" "}
                        Global Beneficiaries
                      </DropdownItem>

                      {/* global Auditors */}
                      <DropdownItem tag={Link} to="/globalauditors">
                        {" "}
                        Global Auditors and Accountants
                      </DropdownItem>


                      {/* global Tax Practitioners */}
                      <DropdownItem tag={Link} to="/globaltaxpractitioners">
                        {" "}
                        Global Tax Practitioners
                      </DropdownItem>

                      {/* global trustees */}
                      <DropdownItem tag={Link} to="/globaltrustees">
                        {" "}
                        Global Trustees
                      </DropdownItem>
                    </SystemViewRenderer>

                    <SystemViewRenderer system={SystemTypeEnum.Liquidation.id}>
                      <DropdownItem tag={Link} to="/globalserviceproviders">
                        {" "}
                        Global Service Providers
                      </DropdownItem>
                    </SystemViewRenderer>

                    <SystemViewRenderer
                      system={[SystemTypeEnum.Curatorship.id]}
                    >
                      <DropdownItem tag={Link} to="/globalexecutors">
                        {" "}
                        Global Tutor/Curator
                      </DropdownItem>
                    </SystemViewRenderer>

                    <SystemViewRenderer
                      system={[SystemTypeEnum.Liquidation.id]}
                    >
                      <DropdownItem tag={Link} to="/guardingcompanies">
                        {" "}
                        Guarding Companies
                      </DropdownItem>
                    </SystemViewRenderer>

                    <SystemViewRenderer system={SystemTypeEnum.Liquidation.id}>
                      <DropdownItem tag={Link} to="/jointappointees">
                        Joint Appointee
                      </DropdownItem>
                    </SystemViewRenderer>

                    <SystemViewRenderer
                      system={[
                        SystemTypeEnum.Liquidation.id,
                        SystemTypeEnum.Curatorship.id,
                        SystemTypeEnum.EstateLate.id,
                        SystemTypeEnum.FlowConsult.id,
                      ]}
                    >
                      <DropdownItem tag={Link} to="/magistratesoffice">
                        {" "}
                        Magistrate's Office
                      </DropdownItem>
                    </SystemViewRenderer>

                    <SystemViewRenderer
                      system={[
                        SystemTypeEnum.Liquidation.id,
                        SystemTypeEnum.EstateLate.id,
                        SystemTypeEnum.Trust.id,
                        SystemTypeEnum.FlowConsult.id,
                      ]}
                    >
                      <DropdownItem tag={Link} to="/marketers">
                        {" "}
                        Marketers
                      </DropdownItem>
                    </SystemViewRenderer>

                    <DropdownItem tag={Link} to="/mastersoffice">
                      {" "}
                      Master's Office
                    </DropdownItem>

                    <SystemViewRenderer
                      system={[
                        SystemTypeEnum.Liquidation.id,
                        SystemTypeEnum.EstateLate.id,
                      ]}
                    >
                      <DropdownItem tag={Link} to="/publicationpartners">
                        {" "}
                        Publication Partners
                      </DropdownItem>
                    </SystemViewRenderer>
                    <DeveloperRenderer>
                      <SystemViewRenderer
                        system={[
                          SystemTypeEnum.EstateLate.id,
                          SystemTypeEnum.FlowConsult.id,
                        ]}
                      >
                        <DropdownItem tag={Link} to="/reconOptions">
                          {" "}
                          Recon Options
                        </DropdownItem>
                      </SystemViewRenderer>
                    </DeveloperRenderer>
                    <SystemViewRenderer
                      system={[
                        SystemTypeEnum.EstateLate.id,
                        SystemTypeEnum.Trust.id,
                        SystemTypeEnum.FlowConsult.id,
                        SystemTypeEnum.Curatorship.id,
                      ]}
                    >
                      <DropdownItem tag={Link} to="/referrers">
                        {" "}
                        Referrers
                      </DropdownItem>
                    </SystemViewRenderer>

                    <SystemViewRenderer system={SystemTypeEnum.Liquidation.id}>
                      <DropdownItem tag={Link} to="/storageproviders">
                        {" "}
                        Storage Providers
                      </DropdownItem>
                    </SystemViewRenderer>

                    <DropdownItem tag={Link} to="/valuers">
                      {" "}
                      Valuers
                    </DropdownItem>

                    <SystemViewRenderer
                      system={[
                        SystemTypeEnum.Liquidation.id,
                        SystemTypeEnum.Curatorship.id,
                        SystemTypeEnum.FlowConsult.id,
                        SystemTypeEnum.EstateLate.id,
                      ]}
                    >
                      <DropdownItem tag={Link} to="/accountdetails">
                        Account Fees
                      </DropdownItem>
                    </SystemViewRenderer>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </AppHeaderDropdown>
            )}

          <NavItem className="px-3">
            <NavLink onClick={this.handleLogout} href="#">
              <i className="fa fa-lock"></i> Logout
            </NavLink>
          </NavItem>
          {/* <NavItem className="d-md-down-none">
            <NavLink href="#"><i className="icon-bell"></i><Badge pill color="danger">5</Badge></NavLink>
          </NavItem>
          <NavItem className="d-md-down-none">
            <NavLink href="#"><i className="icon-list"></i></NavLink>
          </NavItem>
          <NavItem className="d-md-down-none">
            <NavLink href="#"><i className="icon-location-pin"></i></NavLink>
          </NavItem>
          <AppHeaderDropdown direction="down">
            <DropdownToggle nav>
              <img src={'assets/img/avatars/6.jpg'} className="img-avatar" alt="admin@bootstrapmaster.com" />
            </DropdownToggle>
            <DropdownMenu right style={{ right: 'auto' }}>
              <DropdownItem header tag="div" className="text-center"><strong>Account</strong></DropdownItem>
              <DropdownItem><i className="fa fa-bell-o"></i> Updates<Badge color="info">42</Badge></DropdownItem>
              <DropdownItem><i className="fa fa-envelope-o"></i> Messages<Badge color="success">42</Badge></DropdownItem>
              <DropdownItem><i className="fa fa-tasks"></i> Tasks<Badge color="danger">42</Badge></DropdownItem>
              <DropdownItem><i className="fa fa-comments"></i> Comments<Badge color="warning">42</Badge></DropdownItem>
              <DropdownItem header tag="div" className="text-center"><strong>Settings</strong></DropdownItem>
              <DropdownItem><i className="fa fa-user"></i> Profile</DropdownItem>
              <DropdownItem><i className="fa fa-wrench"></i> Settings</DropdownItem>
              <DropdownItem><i className="fa fa-usd"></i> Payments<Badge color="secondary">42</Badge></DropdownItem>
              <DropdownItem><i className="fa fa-file"></i> Projects<Badge color="primary">42</Badge></DropdownItem>
              <DropdownItem divider />
              <DropdownItem><i className="fa fa-shield"></i> Lock Account</DropdownItem>
              <DropdownItem onClick={this.handleLogout} ><i className="fa fa-lock"></i> Logout</DropdownItem>
            </DropdownMenu>
          </AppHeaderDropdown>*/}
        </Nav>
        {/*<AppAsideToggler className="d-md-down-none" />*/}
        {/*<AppAsideToggler className="d-lg-none" mobile />*/}
      </React.Fragment>
    );
  }
}

//DefaultHeader.propTypes = propTypes;
//DefaultHeader.defaultProps = defaultProps;

//export default DefaultHeader;

function mapStateToProps(state, ownProps) {
  const { systemType } = state.authentication;
  const { intermediaryID } = state.authentication;
  const { intermediary } = state;
  const { matterWatchList } = state;
  return {
    systemType,
    intermediary,
    matterWatchList,
  };
}

const connectedDefaultHeader = connect(mapStateToProps)(DefaultHeader);
export { connectedDefaultHeader as DefaultHeader };

//export default connect()(DefaultHeader);
